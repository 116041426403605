
    <script src="https://apis.google.com/js/platform.js" async defer></script>
<template>
  <v-app>
    <div class="page">
      <div class="container">
        <h1>Account</h1>
        <br />
        <br />
        <v-card
          class="mx-auto"
          max-width="800"
        >
          <div style="padding-right: 20px; padding-left: 20px">        
            
            <v-list
              subheader
              three-line
              v-if="loggedIn && this.profile.accountType === `anonymous`"
            >
              <v-subheader>Account Links</v-subheader>
              <v-list-tile v-if="this.profile.accountType === `anonymous`">
                <v-list-tile-content>
                  <v-list-tile-title>Upgrade Account</v-list-tile-title>
                  <v-list-tile-sub-title>Upgrade to use heedi across multiple devices.
                  </v-list-tile-sub-title>
                  
                </v-list-tile-content>
              </v-list-tile>
              <div class="upgradeBtnHolder">
                <g-signin-button
                  v-if="this.profile.accountType === `anonymous`"
                  :params="googleSignInParams"
                  @success="onSignInSuccess"
                  @error="onSignInError">
                  Upgrade with Google Sign-in
                </g-signin-button>
              </div>
              <br>
              
              
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>Create Publisher Account</v-list-tile-title>
                  <v-list-tile-sub-title>Create your own heedi routines for your audience to follow.</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <div class="upgradeBtnHolder" v-if="this.profile.accountType === `anonymous`">
                <g-signin-button
                  :params="googleSignInParams"
                  @success="createPublisherAccount"
                  @error="onSignInError">
                  Create Publisher Account with Google Sign-in
                </g-signin-button>
                
              </div>
              <div v-else>
                <a href="javascript:void(0)" @click="createPublisherAccount">Create Publisher Account</a>

              </div>
              <br>
            

              <br />
              <br />
              <v-divider></v-divider>
              <br />

            </v-list>
            <div class="g-signin2" data-onsuccess="onSignIn" data-theme="dark"></div>
            <v-list
              subheader
              three-line
              v-if="loggedIn"
            >
              <v-subheader>Account Data</v-subheader>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>User Id</v-list-tile-title>
                  <v-list-tile-sub-title>This id uniquely identifies you in our system</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <div class="dataContainer">
                {{ this.transformedUid }}
              </div>
              <br />
              <br />
              

            </v-list>

            
            

            <br>
            <br>
            <br>
            <br>
          </div>
        </v-card>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <VueFooter :dark-text="true" />


    </div>
  </v-app>
</template>


<script>
    import '@/plugins/vuetify'
    //import firebase from '@/firebase'
    import checkinModule from '@/store/checkin'
    //import experienceModule from '@/store/experience'
    //import statementModule from '@/store/statement'
    import settingsModule from '@/store/settings'
    import signinModule from '@/store/signin'
    import userModule from '@/store/user'
    //import ThoughtIntake from '@/components/ThoughtIntake'
    //import NextStepper from '@/components/NextStepper'
    import VueFooter from '@/components/VueFooter'
    import { mapGetters, mapActions } from 'vuex'
    //import * as use from '@tensorflow-models/universal-sentence-encoder';

  export default {
    name: "Account",
    components: {
      VueFooter,
      //FeelingIntake,
      //ThoughtIntake,
      //NextStepper
    },
    data () {
      return {
        e6: 1,
        notificationHistoryId: null,
        userUid: null,
        thoughtObj: null,
        actionStep: '',
        feelingObj: null,
        rules: [
          (v) => !!v || 'Statement is required',
          (v) => v.length <= 60 || 'Max 60 characters'
        ],

        googleSignInParams: {
          client_id: '733999034905-429u3e65mpldmokt1un52qft5bsublas.apps.googleusercontent.com'
        }
      }
    },
    computed: {
      ...mapGetters('cms', [
        'checkInFeelingContent',
        'checkInThoughtContent'
      ]),
      ...mapGetters('auth', [
        'role',
        'profile',
        'loggedIn'
      ]),

      transformedUid () {
        if(this.profile.uid) {
          let transformedUid
          let firstFourChar = this.profile.uid.substring(0,4)
          let lastFourChar = this.profile.uid.substring(this.profile.uid.length - 4)
          let middleCharCt = this.profile.uid.length - 8

          transformedUid = firstFourChar
          for (var i = 0; i < middleCharCt; i++) {
            transformedUid += '*'            
          }
          transformedUid += lastFourChar

          return transformedUid

        }else{
          return null
        }
      }
    },
    methods: {
      
      
      ...mapActions('user', [
        'getUserState',
        'updateNotificationHistory'
      ]),
      
      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn',
        'upgradeAccount'
      ]),
      ...mapActions('auth', [
        'setAutoSignInFlag',
        'setProfile',
        'setAnonymousProfile'
      ]),

      createPublisherAccount() {

      },

      async onSignInSuccess (googleUser) {
        // `googleUser` is the GoogleUser object that represents the just-signed-in user.
        // See https://developers.google.com/identity/sign-in/web/reference#users

        /* eslint-disable no-console */
        

        await this.upgradeAccount({googleUser: googleUser, uid: this.profile.uid})
        console.log("account has been upgraded")
        



      },
      onSignInError (error) {
        // `error` contains any error occurred.
        /* eslint-disable no-console */
        console.log('OH NOES', error)
      },


      
      
      
    },
    beforeCreate () {
      //if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.checkin) this.$store.registerModule('checkin', checkinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      //if(!this.$store.state.statement) this.$store.registerModule('statement', statementModule)
    },
    created () {
      //this.resetCheckIn()
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/check-in'});
      this.autoSignIn()
      
    },
    mounted () {
      
    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>
.page {
  background-color: transparent;
}
.dataContainer {
  width: 70%;
  margin: 0 auto;
}

.stepWrapper {
  max-width: 700px;
  margin: 0 auto;
}

.upgradeBtnHolder {
  background-color: transparent;
  text-align: center;
}

.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
</style>
